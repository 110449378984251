<template>
    <main class="usage">
        <spinner class="usage__spinner" v-if="status === 'loading'" />
        <error-message class="usage__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Использование".
        </error-message>
        <router-view
            v-else
            class="cooking__data"
            :reportDaterange="reportDaterange"
            :compareDaterange="compareDaterange"
            :users="users"
            :methods="methods"
            :minDate="minDate"
            :maxDate="maxDate"
        />
    </main>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

    export default {
        name: "Usage",
        data() {
            return {
                status: "loading",
                // Данные по использованию есть только с 2022-02-07
                minDate: new Date(2022, 1, 7),
                maxDate: new Date(),
            };
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.usage.reportDaterange,
                compareDaterange: state => state.usage.compareDaterange,
                users: state => state.directories.users,
                methods: state => state.directories.methods,
            }),
            ...mapGetters([
                "areDirectoriesLoaded"
            ]),
        },
        methods: {
            ...mapMutations([
                "setUsageReportDaterange",
            ]),
            ...mapActions([
                "getDirectoriesFromDB",
            ]),
            showData() {
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
        },
        created() {
            if (this.reportDaterange.start < this.minDate) {
                this.setUsageReportDaterange({
                    start: this.minDate,
                    end: this.reportDaterange.end
                });
            }

            if (!this.areDirectoriesLoaded) {
                this.getDirectoriesFromDB().then(this.showData, this.showError);
            } else {
                this.showData();
            }
        }
    }
</script>
